<template>
    <v-container :fluid="$store.state.user.fluid">
      <BackendBanner v-if="!$store.getters.checkPermission('can_create_project')" code="project_create_banner"/>
        <v-row v-else>
            <v-col cols="12" lg="6" offset-lg="3">
                <v-card outlined>
                    <v-card-title class="subtitle-1">Информация о заказчике</v-card-title>
                    <v-card-text>
                        <v-text-field autocomplete="off" label="Заказчик" outlined
                                      placeholder="Например Сургутский государственный университет"
                                      v-model="project.customer"  :error-messages="errors.customer"/>

                        <v-autocomplete :items="$store.state.projects.project_types" auto-select-first
                                        autocomplete="off"
                                        item-text="name" item-value="id" label="Тип проекта" outlined
                                        v-model="project.project_type_id"  :error-messages="errors.project_type_id"/>
                      <v-textarea :error-messages="errors.problem" counter="500" dense filled label="Цель проекта"
                                  outlined
                                  v-model="project.participation_purpose"/>
                    </v-card-text>
                </v-card>
                <v-card class="mt-2" outlined>
                    <v-card-title class="subtitle-1">Информация о проекте</v-card-title>
                    <v-card-text>
                        <v-text-field :error-messages="errors.title"  counter="150" dense filled label="Название проекта" outlined
                                      shaped v-model="project.title"/>
                        <v-textarea :error-messages="errors.task" counter="500" dense filled label="Задача" outlined shaped v-model="project.task"/>
                        <v-textarea :error-messages="errors.problem" counter="500" dense filled label="Проблема, которую решает проект"
                                    outlined shaped
                                    v-model="project.problem"/>
                        <v-textarea :error-messages="errors.result" counter="500" dense filled label="Планируемый результат" outlined
                                    shaped v-model="project.result"/>
                        <v-textarea :error-messages="errors.resources"  counter="500" dense filled label="Требуемые материальные ресурсы ресурсы"
                                    outlined shaped
                                    v-model="project.resources"/>

                      <v-combobox :error-messages="errors.product_type"  dense filled label="Тип продукта"
                                    outlined
                                    :items="['изделие', 'приложение', 'мероприятие', 'модель', 'медиа ресурс']"
                                    v-model="project.product_type"/>
                        <v-file-input :error-messages="errors.logotype"  v-model="logotype" label="Логотип проекта" outlined  filled shaped dense/>


                    </v-card-text>

                </v-card>
                <v-card class="mt-2">
                    <v-card-text>
                        <v-btn :loading="loading" @click="save" outlined color="blue darken-2">Добавить проект</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import analytics from "@/api/analytics.js";
import BackendBanner from "@/components/Utility/BackendBanner";

export default {
  name: "ProjectsCreateComponent",
  components: {BackendBanner},

  data() {
    return {
                need_team: false,
                loading: false,
                project: {
                    responsible_user_id: null,
                    project_type_id: null,
                    task: null,
                    problem: null,
                    result: null,
                    title: null,
                    resources: null,
                    customer: null,
                    participation_purpose: null,
                    product_type: null,
                },
                purposes: [],
                logotype: null,
                roles: [],
                errors: []
            }
        },
        methods: {
            addRole() {
                this.roles.push({name: '', tasks: '', quota: 1})
            },

            removeRole(index) {
                this.roles.splice(index, 1);
            },

            save(){
              if (!this.loading) {
                try {
                  analytics.submitProjectCreatePage(this.$store.state.user.currentUser.id ? this.$store.state.user.currentUser.id : 0)
                  this.loading = true;
                  let bodyFormData = new FormData();
                  Object.keys(this.project).forEach((key) => {
                    bodyFormData.set(key, this.project[key])
                  });
                  bodyFormData.set('roles', JSON.stringify([]));
                  if (this.logotype !== null) bodyFormData.append('logotype', this.logotype)
                  this.$store.dispatch('storeProject', bodyFormData).then((r) => {
                    this.$router.push('/projects/' + r.data.id);
                    this.loading = false;
                    window.jsConfetti.addConfetti();
                  }).catch((e) => {
                    this.loading = false;
                    if (e.response.data) {
                      this.errors = e.response.data.errors;
                      analytics.event({
                        event: 'PROJECT_TRY_CREATE_WITH_ERRORS',
                        details: {project: this.project, errors: this.errors}
                      })
                    }
                  })
                } catch (e) {
                  this.loading = false;
                }
              }


            }
        },
        mounted() {
            if(!this.$store.state.user.currentUser.id ) return this.$router.push('/auth')
            this.$store.dispatch('getEmployees');
            this.$store.dispatch('getProjectTypes');
            this.project.responsible_user_id = this.$store.state.user.currentUser.id;
            if(this.$store.state.user.currentUser.role === 'company'){
                this.project.customer = this.$store.state.user.currentUser.company_name;
            }
          this.$emit('changeTitle', 'Создание проекта')
          analytics.openProjectCreatePage(this.$store.state.user.currentUser.id ? this.$store.state.user.currentUser.id : 0)
        }
    }
</script>

<style scoped>
    .list-item {
        display: inline-block;
        margin-right: 10px;
    }

    .list-enter-active, .list-leave-active {
        transition: all 1s;
    }

    .list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */
    {
        opacity: 0;
        transform: translateY(30px);
    }
</style>
