<template>
  <div>
    <v-banner v-if="banner && banner.text"
        class="my-2"
        :color="banner.type"
        dark
        rounded
        shaped
        transition="slide-y-transition"
        two-lines
    >
      {{banner.text}}
      <template v-slot:actions v-if="banner.button">
        <v-btn :href="banner.button.link"
            text
        >
         {{banner.button.text}}
        </v-btn>
      </template>
    </v-banner>
    <div v-if="loading">
      <v-progress-circular indeterminate  :size="20"
                           width="1"
                           color="primary"></v-progress-circular>
    </div>
  </div>

</template>

<script>
import banners from "@/api/banners";

export default {
  name: "BackendBanner",
  props: {
    code: {
      type: String
    }
  },
  data(){
    return {
      banner: null,
      loading: true
    }
  },
  mounted() {
    banners.get(this.code).then((r) => {
      this.banner = r.data;
    }).finally(() => {
      this.loading = false;
    })
  }
}
</script>

<style scoped>

</style>
